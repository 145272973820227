import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetEvents } from "../../api/DashboardRelativeStudent";
import * as moment from "moment";
import SeeAllModule from "./admin/SeeAllModule";
import PropTypes from "prop-types";
import NewDetailsModal from "../dashboard/NewDetailsModal";

const dummyEvent = [
  {
    name: "REUNION DE PADRES DE FAMILIA",
    img: "https://img.freepik.com/vector-premium/reunion-padres-maestro-aula_38747-743.jpg",
    date: "2023-02-10",
  },
  {
    name: "CLAUSURA DE INICIO DE CURSO",
    img: "https://www.colegiosantamariamicaela.com/wp-content/uploads/2021/09/vuelta-a-clases.jpeg",
    date: "2023-03-06",
  },
];

const NewsView = (props) => {
  const [t] = useTranslation(["dashboardStudent", "dashboard"]);
  const prefix = process.env.REACT_APP_PREFIX;
  const [news, setNews] = useState([]);
  const [loadingNew, setLoadingNew] = useState(false);
  const [showNewDetailsModal, setShowNewDetailsModal] = useState(false);
  const [newSelected, setNewSelected] = useState({});

  const heightWindow = window.innerHeight;
  let maxHeightList = heightWindow - heightWindow / 1.6;
  maxHeightList = maxHeightList - 200;

  const customStyles = {
    styleImg: {
      height: "90px",
      width: "175px",
      borderRadius: "5px",
    },
    scrollClass: {
      maxHeight: maxHeightList + "px",
      minHeight: props.sizeNews ? "470px" : "160px",
      overflowY: "auto",
      overflowX: "hidden",
      marginTop: "13px",
    },
    marginText: {
      marginTop: "43px",
      textTransform: "uppercase",
      color: "#ea2c54",
    },
    rowLine: {
      background: "#ffffff",
      border: "1px solid #ececee",
      borderRadius: "0px",
      marginBlock: "10px",
      marginLeft: "0px",
      marginRight: "0px",
    },
    lazy: {
      height: "25px",
    },
    lazyImg: {
      height: "120px",
    },
    imgPopover: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "5px",
      height: "130px",
      backgroundPosition: "center",
    },
    newContentPopover: {
      maxHeight: "150px",
      overflowY: "auto",
    },
  };

  const lazyLoadingNew = () => {
    return (
      <div>
        <Row className="table-lazyLoading">
          <Col md={9} xs={9} className="mt-3">
            <div style={customStyles.lazy}></div>
          </Col>
        </Row>
        <Row className="table-lazyLoading">
          <Col md={6} xs={6} className="mt-3">
            <Col md={12} xs={12}>
              <div style={customStyles.lazy}></div>
            </Col>

            <Col md={12} xs={12} className="mt-5">
              <div style={customStyles.lazy}></div>
            </Col>
          </Col>
          <Col md={6} xs={6} className="mt-3">
            <div style={customStyles.lazyImg}></div>
          </Col>
        </Row>
      </div>
    );
  };

  const getNews = () => {
    setLoadingNew(true);
    if (localStorage.getItem(`cmOrganizationInfo${prefix}`)) {
      const orgInfo = JSON.parse(
        localStorage.getItem(`cmOrganizationInfo${prefix}`)
      );
      const apiKey = orgInfo?.api_key;
      let request = {
        api_key: apiKey,
        offset: 0,
        limit: 5,
      };
      GetEvents(request)
        .then((result) => {
          if (result.status) {
            if (result.data.featured_posts && result.data.featured_posts.error) {
              setNews([]);
              return;
            }
            const news = result.data.featured_posts || [];
            let formatNews = news.map((item) => {
              return {
                id: item.id,
                name: item.title,
                img: item.featured_image_url,
                date: item.date,
                author: item.author_name,
                category: item.categories[0]?.name,
                tags: item.tags[0]?.name,
                dateUnix: item.unix_date_gmt,
                content: item.content,
              };
            });
            setNews(formatNews);
          }
        })
        .catch()
        .finally(() => {
          setLoadingNew(false);
        });
    } else {
      setTimeout(() => {
        getNews();
      }, 1000);
    }
  };

  /**
   * initial effect of a single load
   */
  useEffect(() => {
    if (props.active == false) {
      setNews(dummyEvent);
      setLoadingNew(false);
    } else {
      getNews();
    }
  }, [props.active]);

  return (
    <Row>
      <Col md={12}>
        {loadingNew ? (
          lazyLoadingNew()
        ) : (
          <>
            <Row>
              <Col xs={12} md={12} xl={12} className="mt-3">
                <i className="bi bi-newspaper me-2 fs-6"></i>
                <b className="fs-6">{t("textNewView.titleNew")}</b>
              </Col>
            </Row>
            <Row style={customStyles.scrollClass}>
              {news.length ? (
                <Col md={12}>
                  {news.map((item) => {
                    return (
                      <Row
                        key={item.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNewSelected(item);
                          setShowNewDetailsModal(true);
                        }}
                      >
                        <Col xs={6}>
                          <Row>
                            <Col md={12}>
                              <p className="text-truncate">{item.name}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <p style={customStyles.marginText}>
                                {moment
                                  .unix(item.dateUnix)
                                  .local()
                                  .format("MMMM DD, YYYY")}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={6}>
                          <img
                            src={item.img}
                            style={customStyles.styleImg}
                            className="float-end"
                          />
                        </Col>
                        <Col xs={12}>
                          <Row style={customStyles.rowLine}></Row>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              ) : (
                <Row>
                  <Col md={12} className=" align-middle">
                    <p className="text-center">
                      {t("dashboard:labels.noData")}
                    </p>
                  </Col>
                </Row>
              )}
            </Row>
            <Row>
              <Col md={12} className="mt-2">
                {news.length > 0 && (
                  <SeeAllModule isCMv1={true} path={"/news"} />
                )}
              </Col>
            </Row>
            {showNewDetailsModal && (
              <NewDetailsModal
                showNewDetailsModal={showNewDetailsModal}
                size={"lg"}
                newDetail={newSelected}
                fnCancelButton={() => setShowNewDetailsModal(false)}
                txtBtnCancel={t("global:buttons.close")}
              />
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default NewsView;

NewsView.propTypes = {
  active: PropTypes.bool,
  sizeNews: PropTypes.bool,
};
